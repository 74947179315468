import httpUtil from "@/utils/httpUtil";

/** 添加轮播图*/
export function addChart(params,type) {
    return httpUtil.post("/api/assPc/square/addChart", params, type)
}
/** 修改轮播图 */
export function upChart(params,type) {
    return httpUtil.post("/api/assPc/square/upChart", params, type)
}
/** 删除轮播图 */
export function delChart(params,type) {
    return httpUtil.post("/api/assPc/square/delChart", params, type)
}
/** 获取轮播图 */
export function selectChartList(params,type) {
    return httpUtil.post("/api/assPc/square/selectChartPageList", params, type)
}

/** 添加日常圈子*/
export function addCircleDaily(params,type) {
    return httpUtil.post("/api/assPc/square/addCircleDaily", params, type)
}
/** 修改日常圈子 */
export function upCircleDaily(params,type) {
    return httpUtil.post("/api/assPc/square/upCircleDaily", params, type)
}
/** 删除日常圈子 */
export function delCircleDaily(params,type) {
    return httpUtil.post("/api/assPc/square/delCircleDaily", params, type)
}
/** 获取日常圈子 */
export function selectCircleDailyList(params,type) {
    return httpUtil.post("/api/assPc/square/selectCircleDailyPageList", params, type)
}
/** 查看日常圈子 */
export function queryCircleDailyById(params,type) {
    return httpUtil.post("/api/assPc/square/queryCircleDailyById", params, type)
}

/** 添加人脉对接*/
export function addCircleWork(params,type) {
    return httpUtil.post("/api/assPc/square/addCircleWork", params, type)
}
/** 修改人脉对接 */
export function upCircleWork(params,type) {
    return httpUtil.post("/api/assPc/square/upCircleWork", params, type)
}
/** 删除人脉对接 */
export function delCircleWork(params,type) {
    return httpUtil.post("/api/assPc/square/delCircleWork", params, type)
}
/** 获取人脉对接 */
export function selectCircleWorkList(params,type) {
    return httpUtil.post("/api/assPc/square/selectCircleWorkPageList", params, type)
}
/** 查看人脉对接 */
export function queryCircleWorkById(params,type) {
    return httpUtil.post("/api/assPc/square/queryCircleWorkById", params, type)
}

/** 添加招商引资*/
export function addCircleAttract(params,type) {
    return httpUtil.post("/api/assPc/square/addCircleAttract", params, type)
}
/** 修改招商引资 */
export function upCircleAttract(params,type) {
    return httpUtil.post("/api/assPc/square/upCircleAttract", params, type)
}
/** 删除招商引资 */
export function delCircleAttract(params,type) {
    return httpUtil.post("/api/assPc/square/delCircleAttract", params, type)
}
/** 获取招商引资 */
export function selectCircleAttractList(params,type) {
    return httpUtil.post("/api/assPc/square/selectCircleAttractPageList", params, type)
}
/** 查看招商引资 */
export function queryCircleAttractById(params,type) {
    return httpUtil.post("/api/assPc/square/queryCircleAttractById", params, type)
}
/** 获取评论列表 */
export function selectDiscussList(params,type) {
    return httpUtil.post("/api/assPc/square/selectDiscussPageList", params, type)
}
/** 删除评论 */
export function delDiscuss(params,type) {
    return httpUtil.post("/api/assPc/square/delDiscuss", params, type)
}
/** 审核日志 */
export function selectExaminePageList(params,type) {
    return httpUtil.post("/api/assPc/square/selectExaminePageList", params, type)
}
/** 发布动态   */
export function addCompanyDynamic(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/addCompanyDynamic", params, type)
}
/** 动态列表   */
export function queryCompanyDynamicPage(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/queryCompanyDynamicPage", params, type)
}

/** 修改动态列表   */
export function updateCompanyDynamicById(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/updateCompanyDynamicById", params, type)
}

/** 删除动态列表   */
export function delCompanyDynamicById(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/delCompanyDynamicById", params, type)
}
/** 动态详情  */
export function queryCompanyDynamicById(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/queryCompanyDynamicById", params, type)
}
