import httpUtil from "@/utils/httpUtil";

/** 添加组织信息 */
export function addAssociation(params,type) {
    return httpUtil.post("/api/assPc/association/addAssociation", params, type)
}
/** 修改组织信息 */
export function upAssociation(params,type) {
    return httpUtil.post("/api/assPc/association/upAssociation", params, type)
}
/** 获取组织信息 */
export function fetchIndustryList(params,type) {
    return httpUtil.get("/api/crmPc/companyGoods/getList", params, type)
}
/** 行业信息 */
export const selectAssociationList = async params => httpUtil.post("/api/assPc/association/selectAssociationList", params);

/** 添加等级 */
export function addMemberLevel(params,type) {
    return httpUtil.post("/api/assPc/association/addMemberLevel", params, type)
}
/** 编辑等级 */
export function upMemberLevel(params,type) {
    return httpUtil.post("/api/assPc/association/upMemberLevel", params, type)
}
/** 查询等级列表 */
export const selectMemberLevelList = async params => httpUtil.post("/api/assPc/association/selectMemberLevelPageList", params);
/** 删除等级 */
export const delMemberLevel = async params => httpUtil.post("/api/assPc/association/delMemberLevel", params);

/** 获取类型 */
export function selectOrganizationContentList(params,type) {
    return httpUtil.post("/api/assPc/association/selectOrganizationContentList", params, type)
}
/** 获取会员会费 */
export const selectMemberFeeList = async params => httpUtil.post("/api/assPc/association/selectMemberFeeList", params);
/** 新增章程 */
export function addOrganizationContent(params,type) {
    return httpUtil.post("/api/assPc/association/addOrganizationContent", params, type)
}
/** 新增章程 */
export function upOrganizationContent(params,type) {
    return httpUtil.post("/api/assPc/association/upOrganizationContent", params, type)
}
/** 修改会费 */
export function upMemberFee(params,type) {
    return httpUtil.post("/api/assPc/association/upMemberFee", params, type)
}
/** 新增会费  性别 */
export function addMemberFee(params,type) {
    return httpUtil.post("/api/assPc/association/addMemberFee", params, type)
}
/** 查询关于我们 */
export function selectAboutUsList(params,type) {
    return httpUtil.post("/api/assPc/association/selectAboutUsList", params, type)
}
/** 新增关于我们 */
export function addAboutUs(params,type) {
    return httpUtil.post("/api/assPc/association/addAboutUs", params, type)
}
/** 新增关于我们 */
export function upAboutUs(params,type) {
    return httpUtil.post("/api/assPc/association/upAboutUs", params, type)
}
/** 查询申请书 */
export function selectJoinApplyList(params,type) {
    return httpUtil.post("/api/assPc/association/selectJoinApplyPageList", params, type)
}
/** 新增申请书 */
export function addJoinApply(params,type) {
    return httpUtil.post("/api/assPc/association/addJoinApply", params, type)
}
/** 修改申请书 */
export function upJoinApply(params,type) {
    return httpUtil.post("/api/assPc/association/upJoinApply", params, type)
}

//修改会费设置
export function updataMemberMoney(params,type){
    return httpUtil.post("/api/assPc/association/upMemberFeeList", params, type)
}

