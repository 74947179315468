import httpUtil from "@/utils/httpUtil";

export const getProductsInfo = (params,type) => httpUtil.post("/api/crmPc/products/getProductsInfo", params,type);

export const selectProductsTypeList = params => httpUtil.post("/api/crmPc/products/selectProductsTypeList", params);
export const insertProducts = (params,type) => httpUtil.post("/api/crmPc/products/insertProducts", params,type);




//资料库  --- 列表
export const queryPageList = (params,type) => httpUtil.post("/api/crmPc/dataInfoMation/dataInfoMation/queryPageList", params,type);

//资料库  --- 详情
export const queryDataOne = (params,type) => httpUtil.post("/api/crmPc/dataInfoMation/dataInfoMation/queryDataOne", params,type);

//资料库  --- 角标
export const querCountIsSeeNum = (params,type) => httpUtil.post("/api/crmPc/dataInfoMation/dataInfoMation/querCountIsSeeNum", params,type);
//资料库  ----- 修改
export const updteData = (params,type) => httpUtil.post("/api/crmPc/dataInfoMation/dataInfoMation/updteData", params,type);

//资料库类型
export const queryPageListType = (params,type) => httpUtil.post("/api/assPc/content/dataInfoMationType/queryPageList", params,type);
//获取太阳码
export const getFollowCode = (params,type) => httpUtil.post("/api/crmPc/home/getFollowCode", params,type);
