<template>
  <div class="view pa24">
    <div class="details_title">
      {{details.dataTitle}}
    </div>
    <div style="text-align: center;margin-top: 15px">
      <el-button type="info" style="background: #FFFFFF;color: #909399" @click="centerDialogVisible = true">引用该文章</el-button>
    </div>
    <div class="details_date">
      <div>类型：{{details.name}}</div>
      <div>上传时间：{{details.createTime}}</div>
    </div>
    <div class="details_con" v-html="details.dataInfo">

    </div>

    <el-dialog
        title="选择类型"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <div style="text-align: center;margin: 30px 0;display: flex;flex-direction: column">
        <el-radio-group v-model="type">
          <el-radio :label="0">公告</el-radio>
          <el-radio :label="1">组织工作</el-radio>
          <el-radio :label="2">文章管理</el-radio>
          <el-radio :label="3">动态列表</el-radio>
        </el-radio-group>
        <el-radio-group v-model="square_type" v-if="type==3" style="margin-top: 30px">
          <el-radio :label="0">日常圈子</el-radio>
          <el-radio :label="1">招商引资</el-radio>
          <el-radio :label="2">人脉对接</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toDetails">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="添加公告"
        :visible.sync="centerDialogVisible_GG"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close_GG"
    >
      <el-form
          :model="ruleForm_GG"
          :rules="rules_GG"
          ref="ruleForm_GG"
          label-width="78px"
      >
        <el-form-item label="公告标题" prop="title" label-width="130px">
          <el-input v-model="ruleForm_GG.title" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="简述" prop="sketch"  label-width="130px">
          <el-input type="textarea" v-model="ruleForm_GG.sketch" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="公告详情" label-width="130px" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_GG.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_GG($event)"
          />
          <span class="wordNumber">{{TiLength_GG}}/10000</span>
        </el-form-item>
        <el-form-item label="可见成员" label-width="130px" prop="isDistribution">
          <el-radio-group v-model="ruleForm_GG.isDistribution">
            <el-radio :label="0">所有人</el-radio>
            <el-radio :label="1">所有会员</el-radio>
            <el-radio :label="2">指定会员</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="ruleForm_GG.isDistribution == 2">
          <el-select
              multiple
              v-model="ruleForm_GG.tjMembers"
              style="width: 100%"
              placeholder="请选择会员"
          >
            <el-option
                v-for="(item) in memberList"
                :key="item.memberId"
                :label="item.userName"
                :value="item.memberId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="会员模板推送" label-width="130px" prop="members">
          <el-switch
              v-model="ruleForm_GG.members"
              active-color="#51CDCB"
              inactive-color="#b8b8b8">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_GG = false">取 消</el-button>
        <el-button type="primary" @click="determine_GG">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发布文章"
        :visible.sync="centerDialogVisible_ZZ"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close_ZZ"
    >
      <el-form
          :model="ruleForm_ZZ"
          :rules="rules_ZZ"
          ref="ruleForm_ZZ"
          label-width="150px"
      >
        <el-form-item label="文章名称" prop="title">
          <el-input v-model="ruleForm_ZZ.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="type">
          <el-select
              v-model="ruleForm_ZZ.type"
              style="width: 100%"
          >
            <el-option
                v-for="(item) in typeList_ZZ"
                :key="item.articleTypeId"
                :label="item.name"
                :value="item.articleTypeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm_ZZ.sort" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="coverImg">
          <el-upload
              :action="$store.state.uploadingUrl"
              list-type="picture-card"
              :on-progress="handProgress_ZZ"
              :on-success="handleAvatarSuccessCoverImg_ZZ"
              :on-remove="handleRemove_ZZ"
              :file-list="fileList_ZZ"
              :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm_ZZ.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="置顶" prop="ifTop">
          <el-switch
              v-model="ruleForm_ZZ.ifTop"
              active-color="#51CDCB"
              inactive-color="#dadde5">
          </el-switch>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-input type="textarea" v-model="ruleForm_ZZ.desc" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_ZZ.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_ZZ($event)"
          />
          <span class="wordNumber">{{TiLength_ZZ}}/2000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_ZZ = false">取 消</el-button>
        <el-button type="primary" @click="determine_ZZ">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发布文章"
        :visible.sync="centerDialogVisible_WZ"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close_WZ"
    >
      <el-form
          :model="ruleForm_WZ"
          :rules="rules_WZ"
          ref="ruleForm_WZ"
          label-width="150px"
      >
        <el-form-item label="文章名称" prop="title">
          <el-input v-model="ruleForm_WZ.title" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <!--        @change="toChoose"-->
        <el-form-item label="类型" prop="type">
          <el-select
              @change="chooseType"
              v-model="ruleForm_WZ.type"
              style="width: 100%"
          >
            <el-option
                v-for="(item) in typeList_WZ"
                :key="item.articleTypeId"
                :label="item.name"
                :value="item.articleTypeId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="ruleForm_WZ.sort" maxlength="11" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="isState">
          <el-radio-group v-model="ruleForm_WZ.isState">
            <el-radio :label="0">显示</el-radio>
            <el-radio :label="1">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="视频内容" prop="videoUrl" v-if="articeShow==2">
          <el-upload
              class="avatar-uploader"
              :action="$store.state.uploadingUrl"
              :show-file-list="false"
              accept=".mp4"
              :on-progress="handProgress_WZ"
              :on-success="handleAvatarSuccessVideo_WZ"
              :before-upload="beforeAvatarUploadVideo_WZ"
          >
            <video
                v-if="ruleForm_WZ.videoUrl"
                :src="ruleForm_WZ.videoUrl"
                width="100%"
                height="100%"
                controls
            >
              <source src="movie.mp4" type="video/mp4" />
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              只能上传MP4文件，且不超过20M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="图片" prop="coverImg">
          <el-upload
              :class="{
              'avatar-uploader': true,
              'avatar-coverImg': true,
            }"
              :action="$store.state.uploadingUrl"
              list-type="picture-card"
              :on-progress="handProgress_WZ"
              :on-success="handleAvatarSuccessCoverImg_WZ"
              :on-remove="handleRemove_WZ"
              :file-list="fileList_WZ"
              :limit="1"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
            <div slot="tip" class="el-upload__tip">
              上传图片的最佳尺寸：750像素*750像素，其他尺寸会影响页面效果，格式png，jpeg，jpg。大小不超过2M
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="置顶" prop="ifTop">
          <el-switch
              v-model="ruleForm_WZ.ifTop"
              active-color="#51CDCB"
              inactive-color="#dadde5">
          </el-switch>
        </el-form-item>
        <el-form-item label="简介" prop="desc">
          <el-input type="textarea" v-model="ruleForm_WZ.desc" maxlength="500" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_WZ.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_WZ($event)"
          />
          <span class="wordNumber">{{TiLength_WZ}}/2000</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_WZ = false">取 消</el-button>
        <el-button type="primary" @click="determine_WZ">确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
        title="发布日常圈子"
        :visible.sync="centerDialogVisible_QZ"
        @close="close_QZ"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm_QZ" :model="ruleForm_QZ" :rules="rules_QZ" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="动态标题">
          <el-input
              v-model="ruleForm_QZ.title"
              style="width: 100%"
              placeholder="请输入动态标题"
              maxlength="100"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="goodPhoto" label="轮播图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview_QZ"
                :on-remove="handleRemove_QZ"
                :on-success="goodsUpload_QZ"
                :file-list="ruleForm_QZ.goodPhoto"
                :limit="15"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible_QZ"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl_QZ" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="发布内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_QZ.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_QZ($event)"
          />
          <span class="wordNumber">{{TiLength_QZ}}/1500</span>
        </el-form-item>
        <el-form-item label="是否展示单位信息" prop="ifShow">
          <el-switch
              v-model="ruleForm_QZ.ifShow"
              active-color="#51CDCB"
              inactive-color="#b8b8b8">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_QZ = false">取 消</el-button>
        <el-button type="primary" @click="determine_QZ">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发布动态"
        :visible.sync="centerDialogVisible_ZS"
        @close="close_ZS"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm_ZS" :model="ruleForm_ZS" :rules="rules_ZS" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="动态标题">
          <el-input
              v-model="ruleForm_ZS.title"
              style="width: 100%"
              placeholder="请输入动态标题"
              maxlength="100"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="招商类型">
          <el-select
              v-model="ruleForm_ZS.type"
              style="width: 100%"
              placeholder="请选择招商类型"
          >
            <el-option
                v-for="(item) in typeList_ZS"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="goodPhoto" label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled1,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview_ZS"
                :on-remove="handleRemove_ZS"
                :on-success="goodsUpload_ZS"
                :file-list="ruleForm_ZS.goodPhoto"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible_ZS"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl_ZS" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="发布内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_ZS.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_ZS($event)"
          />
          <span class="wordNumber">{{TiLength_ZS}}/1500</span>
        </el-form-item>
        <el-form-item label="是否展示单位信息" prop="ifShow">
          <el-switch
              v-model="ruleForm_ZS.ifShow"
              active-color="#51CDCB"
              inactive-color="#b8b8b8">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_ZS = false">取 消</el-button>
        <el-button type="primary" @click="determine_ZS">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="发布动态"
        :visible.sync="centerDialogVisible_BS"
        @close="close_BS"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-form
          ref="ruleForm_BS" :model="ruleForm_BS" :rules="rules_BS" label-width="auto" class="view pa24" label-position="left"
      >
        <el-form-item prop="title" label="动态标题">
          <el-input
              v-model="ruleForm_BS.title"
              style="width: 100%"
              placeholder="请输入动态标题"
              maxlength="100"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="type" label="行业类型">
          <el-select
              v-model="ruleForm_BS.type"
              style="width: 100%"
              placeholder="请选择行业类型"
          >
            <el-option
                v-for="(item) in industryList_BS"
                :key="item.industryId"
                :label="item.industryName"
                :value="item.industryId"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="goodPhoto" label="缩略图">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            上传图片的最佳尺寸：800像素*800像素，其他尺寸会影响页效果，格式png，jpeg，jpg。大小不超过4M，商品图片一共可以上传5张，默认第一张为主图封面
          </p>
          <div style="display: flex">
            <el-upload
                :action="uploadUrl"
                :class="{
              disabled: uploadDisabled1,
            }"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview_BS"
                :on-remove="handleRemove_BS"
                :on-success="goodsUpload_BS"
                :file-list="ruleForm_BS.goodPhoto"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible_BS"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl_BS" alt="" />
            </el-dialog>
          </div>
        </el-form-item>
        <el-form-item label="发布内容" prop="details">
          <!-- //富文本 -->
          <quill-editor
              class="ql-editor"
              style="height: 100%"
              v-model="ruleForm_BS.details"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange_BS($event)"
          />
          <span class="wordNumber">{{TiLength_BS}}/1500</span>
        </el-form-item>
        <el-form-item label="是否展示单位信息" prop="ifShow">
          <el-switch
              v-model="ruleForm_BS.ifShow"
              active-color="#51CDCB"
              inactive-color="#b8b8b8">
          </el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible_BS = false">取 消</el-button>
        <el-button type="primary" @click="determine_BS">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {quillEditor, Quill} from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import {ImageDrop} from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写内容'
import {addWork,selectArticleTypePageList,selectArticleTypeList,addOrgNotice} from "@/api/content";
import {addCircleDaily,addCircleAttract,addCircleWork} from "@/api/square";
import {fetchIndustryList} from '@/api/organization.js'
import {queryDataOne} from '@/api/management'
export default {
  name: "dataDetails",
  data(){
    return{
      dataId:'',
      details:{},

      editorOption: quillConfig,
      TiLength_GG:0,
      TiLength_ZZ:0,
      TiLength_WZ:0,
      TiLength_QZ:0,
      TiLength_ZS:0,
      TiLength_BS:0,
      centerDialogVisible:false,
      centerDialogVisible_GG:false,
      centerDialogVisible_ZZ:false,
      centerDialogVisible_WZ:false,
      centerDialogVisible_QZ:false,
      centerDialogVisible_ZS:false,
      centerDialogVisible_BS:false,
      type:0,
      isUpdate:true,
      square_type:0,
      ruleForm_GG: {
        title: "",
        details: "",
        isDistribution: 0,
        members: true,
        sketch:"",
        tjMembers:[],
      },
      rules_GG: {
        title: [{required: true, message: "请输入公告标题", trigger: "blur"}],
        sketch: [{required: true, message: "请输入简述", trigger: "blur"}],
        details: [{required: true, message: "请输入公告详情", trigger: "blur"}],
        isDistribution: [{required: true, message: "请选择可见成员", trigger: "blur"}],
        members: [{required: true, message: "请选择会员推送模板", trigger: "blur"}],
      },
      ruleForm_ZZ: {
        title: "",
        sort: "",
        isState: 0,
        coverImg:'',
        type:'',
        ifTop:true,
        details: "",
        desc:''
      },
      rules_ZZ: {
        title: [{ required: true, message: "请输入文章名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        desc: [{ required: true, message: "请输入简介", trigger: "blur" }],
        ifTop: [{ required: true, message: "请选择是否置顶"}],
        isState: [{ required: true, message: "请选择状态"}],
        type: [{ required: true, message: "请选择分类"}],
      },
      fileList_ZZ: [],
      typeList_ZZ:[],
      ruleForm_WZ: {
        title: "",
        sort: "",
        isState: 0,
        coverImg:'',
        videoUrl:'',
        type:'',
        ifTop:true,
        desc:'',
        details: "",
      },
      rules_WZ: {
        title: [{ required: true, message: "请输入文章名称", trigger: "blur" }],
        coverImg: [{ required: true, message: "请上传图片" }],
        sort: [{ required: true, message: "请输入排序", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        desc: [{ required: true, message: "请输入简介", trigger: "blur" }],
        ifTop: [{ required: true, message: "请选择是否置顶"}],
        isState: [{ required: true, message: "请选择状态"}],
        type: [{ required: true, message: "请选择类型"}],
      },
      typeList_WZ:[],
      fileList_WZ:[],
      articeShow:1,
      ruleForm_QZ: {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false
      },
      dialogVisible_QZ: false,
      dialogImageUrl_QZ: "",
      rules_QZ: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        goodPhoto: [{ required: true, message: "请上传图片"}],
        ifShow: [{ required: true, message: "请选择是否展示单位信息"}],
      },
      uploadUrl: this.$store.state.uploadingUrl,
      ruleForm_ZS: {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false,
        type:''
      },
      dialogVisible_ZS: false,
      dialogImageUrl_ZS: "",
      rules_ZS: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        goodPhoto: [{ required: true, message: "请上传图片"}],
        ifShow: [{ required: true, message: "请选择是否展示单位信息"}],
        type: [{ required: true, message: "请选择类型"}],
      },
      typeList_ZS:[{id:1,name:'有项目-找资金'},{id:2,name:'有资金-找项目'},{id:3,name:'有项目、有资金-找地方落户'},{id:4,name:'有项目、有技术、有品牌-找资金'},{id:5,name:'没项目、没技术、没资金-出让土地'},],
      ruleForm_BS: {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false,
        type:''
      },
      dialogVisible_BS: false,
      dialogImageUrl_BS: "",
      rules_BS: {
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        details: [{ required: true, message: "请输入内容", trigger: "blur" }],
        goodPhoto: [{ required: true, message: "请上传图片"}],
        ifShow: [{ required: true, message: "请选择是否展示单位信息"}],
        type: [{ required: true, message: "请选择类型"}],
      },
      industryList_BS:[]
    }
  },
  computed: {
    uploadDisabled() {
      return this.ruleForm_QZ.goodPhoto.length >= 15;
    },
    uploadDisabled1() {
      return this.ruleForm_ZS.goodPhoto.length >= 1;
    },
  },
  mounted() {
    this.dataId = this.$route.query.dataId
    this.getDetails(this.dataId)
  },
  methods:{
    getDetails(dataId){
      let data = {
        dataId:dataId
      }
      queryDataOne(data)
          .then(res=>{
            let data = res.data
            this.details = data
          })
          .catch(err=>{
            console.log(err)
          })
    },
    //获取行业列表
    getIndustryList(){
      fetchIndustryList()
          .then(res=>{
            this.industryList_BS = res.data
          })
          .catch(err=>{
            console.log(err)
          })
    },
    onEditorChange_GG(e){
      if(this.ruleForm_GG.details==''){
        this.TiLength_GG = 0
      }else{
        this.TiLength_GG = e.quill.getLength()-1
      }
    },
    onEditorChange_ZZ(e){
      if(this.ruleForm_ZZ.details==''){
        this.TiLength_ZZ = 0
      }else{
        this.TiLength_ZZ = e.quill.getLength()-1
      }
    },
    onEditorChange_WZ(e){
      if(this.ruleForm_WZ.details==''){
        this.TiLength_WZ = 0
      }else{
        this.TiLength_WZ = e.quill.getLength()-1
      }
    },
    onEditorChange_QZ(e){
      if(this.ruleForm_QZ.details==''){
        this.TiLength_QZ = 0
      }else{
        this.TiLength_QZ = e.quill.getLength()-1
      }
    },
    onEditorChange_ZS(e){
      if(this.ruleForm_ZS.details==''){
        this.TiLength_ZS = 0
      }else{
        this.TiLength_ZS = e.quill.getLength()-1
      }
    },
    onEditorChange_BS(e){
      if(this.ruleForm_BS.details==''){
        this.TiLength_BS = 0
      }else{
        this.TiLength_BS = e.quill.getLength()-1
      }
    },
    toDetails(){
      console.log(this.type)
      if (this.type == 0) {
        this.centerDialogVisible_GG = true
        this.ruleForm_GG.title = this.details.dataTitle
        this.ruleForm_GG.details = this.details.dataInfo
      }else if (this.type == 1) {
        this.getTypeList()   //组织工作 ---- 文章分类
        this.centerDialogVisible_ZZ = true
        this.ruleForm_ZZ.title = this.details.dataTitle
        this.ruleForm_ZZ.details = this.details.dataInfo
      }else if (this.type == 2) {
        this.getTypeList1()   //文章管理 ----- 文章类型
        this.centerDialogVisible_WZ = true
        this.ruleForm_WZ.title = this.details.dataTitle
        this.ruleForm_WZ.details = this.details.dataInfo
      }else if (this.type == 3) {
        if (this.square_type == 0) {
          this.centerDialogVisible_QZ = true
          this.ruleForm_QZ.title = this.details.dataTitle
          this.ruleForm_QZ.details = this.details.dataInfo
        }else if (this.square_type == 1) {
          this.centerDialogVisible_ZS = true
          this.ruleForm_ZS.title = this.details.dataTitle
          this.ruleForm_ZS.details = this.details.dataInfo
        }else if (this.square_type == 2) {
          this.getIndustryList()   //行业类型
          this.centerDialogVisible_BS = true
          this.ruleForm_BS.title = this.details.dataTitle
          this.ruleForm_BS.details = this.details.dataInfo
        }
      }
    },
    close_GG(){
      (this.ruleForm_GG = {
        title: "",
        details: "", //商品详情
        isDistribution: "",
        members: true
      }),
          this.centerDialogVisible_GG = false;
    },
    close_ZZ(){
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        coverImg:'',
        type:'',
        ifTop:true,
        details:'',
        desc:''
      }),
          this.centerDialogVisible_ZZ = false;
    },
    close_WZ(){
      (this.ruleForm = {
        title: "",
        sort: "",
        isState: 0,
        coverImg:'',
        type:'',
        ifTop:true,
        details:'',
        desc:''
      }),
          this.centerDialogVisible_WZ = false;
    },
    close_QZ(){
      (this.ruleForm_QZ = {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false
      }),
          this.centerDialogVisible_QZ = false;
    },
    close_ZS(){
      (this.ruleForm_ZS = {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false
      }),
          this.centerDialogVisible_ZS = false;
      this.fileList_ZS = []
    },
    close_BS(){
      (this.ruleForm = {
        title:"",
        details:"",
        goodPhoto:[],
        ifShow:false
      }),
          this.centerDialogVisible = false;
      this.fileList_BS = []
    },
    async getTypeList(){
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 0
      };
      try {
        const result = await selectArticleTypePageList(data);
        const { total, list } = result.data.pageInfo;
        this.typeList_ZZ = list;
      } catch (error) {
        this.loading = false;
      }
    },
    async getTypeList1(){
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        type: 1,
        status:0
      };
      try {
        const result = await selectArticleTypeList(data);
        console.log(result)
        this.typeList_WZ = result.data;
        this.queryPage();
      } catch (error) {
        this.loading = false;
      }
    },
    determine_GG(){
      this.$refs.ruleForm_GG.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              title: this.ruleForm_GG.title,
              content: this.ruleForm_GG.details,
              sketch: this.ruleForm_GG.sketch,
              visibleType: this.ruleForm_GG.isDistribution,
              ifSend: this.ruleForm_GG.members?0:1,
              memberUsers:JSON.stringify(this.ruleForm_GG.tjMembers)
            };
            this.$message({
              message: "正在保存",
            });
            await addOrgNotice(data);
            this.centerDialogVisible_GG = false;
            this.centerDialogVisible = false
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_GG = false;
            this.centerDialogVisible = false
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    determine_ZZ(){
      this.$refs.ruleForm_ZZ.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm_ZZ.title,
              orderBy:this.ruleForm_ZZ.sort,
              status:this.ruleForm_ZZ.isState,
              classType:this.ruleForm_ZZ.type,
              ifTop:this.ruleForm_ZZ.ifTop?0:1,
              remark:this.ruleForm_ZZ.details,
              content:this.ruleForm_ZZ.desc,
              coverUrl:this.ruleForm_ZZ.coverImg,
              type:0
            };
            this.$message({
              message: "正在保存",
            });
            await addWork(data);
            this.centerDialogVisible_ZZ = false;
            this.centerDialogVisible = false
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_ZZ = false;
            this.centerDialogVisible = false
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    determine_WZ(){
      this.$refs.ruleForm_WZ.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm_WZ.title,
              orderBy:this.ruleForm_WZ.sort,
              status:this.ruleForm_WZ.isState,
              classType:this.ruleForm_WZ.type,
              ifTop:this.ruleForm_WZ.ifTop?0:1,
              remark:this.ruleForm_WZ.details,
              type:1,
              coverUrl:this.ruleForm_WZ.coverImg,
              content:this.ruleForm_WZ.desc,
            };
            if (this.articeShow == 2) {
              data.jumpType = 3
              data.url = this.ruleForm_WZ.videoUrl
            }
            this.$message({
              message: "正在保存",
            });
            await addWork(data);
            this.centerDialogVisible_WZ = false;
            this.centerDialogVisible = false
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_WZ = false;
            this.centerDialogVisible = false
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    determine_QZ(){
      if(this.ruleForm_QZ.goodPhoto.length == 0){
        return this.$message({
          message: "图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm_QZ.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm_QZ.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              userId: localStorage.getItem("userId"),
              userName: localStorage.getItem("userName"),
              title:this.ruleForm_QZ.title,
              content:this.ruleForm_QZ.details,
              urlList:arr.join(","),
              ifShow:this.ruleForm_QZ.ifShow?0:1,
            };
            this.$message({
              message: "正在保存",
            });
            await addCircleDaily(data);
            this.centerDialogVisible_QZ = false;
            this.centerDialogVisible = false
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_QZ = false;
            this.centerDialogVisible = false
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    determine_ZS(){
      if(this.ruleForm_ZS.goodPhoto.length == 0){
        return this.$message({
          message: "图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm_ZS.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm_ZS.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              userId: localStorage.getItem("userId"),
              userName: localStorage.getItem("userName"),
              title:this.ruleForm_ZS.title,
              content:this.ruleForm_ZS.details,
              thumbnail:arr.join(","),
              ifShow:this.ruleForm_ZS.ifShow?0:1,
              type:this.ruleForm_ZS.type
            };
            this.$message({
              message: "正在保存",
            });
            await addCircleAttract(data);
            this.centerDialogVisible_ZS = false;
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_ZS = false;
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    determine_BS(){
      if(this.ruleForm_BS.goodPhoto.length == 0){
        return this.$message({
          message: "图片未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm_BS.validate(async (valid) => {
        if (valid) {
          try {
            let arr = this.ruleForm_BS.goodPhoto.map((item) => item.url);
            let data = {
              associationId: localStorage.getItem("associationId"),
              userId: localStorage.getItem("userId"),
              userName: localStorage.getItem("userName"),
              title:this.ruleForm_BS.title,
              content:this.ruleForm_BS.details,
              thumbnail:arr.join(","),
              ifShow:this.ruleForm_BS.ifShow?0:1,
              type:this.ruleForm_BS.type
            };
            this.$message({
              message: "正在保存",
            });
            await addCircleWork(data);
            this.centerDialogVisible_BS = false;
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible_BS = false;
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //选择分类
    chooseType(item){
      let index = null
      for (let i in this.typeList_WZ) {
        if (this.typeList_WZ[i].articleTypeId == item) {
          index = i
        }
      }
      if (index == 1) {
        this.articeShow = 2
      }else {
        this.articeShow = 1
      }
    },
    handleRemove_ZZ(file, fileList) {
      this.ruleForm_ZZ.coverImg = "";
    },
    /**@method 上传时回调 */
    handProgress_ZZ(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;

    },
    handleAvatarSuccessCoverImg_ZZ(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm_ZZ.coverImg = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },
    handleRemove_WZ(file, fileList) {
      this.ruleForm_WZ.coverImg = "";
    },
    /**@method 上传时回调 */
    handProgress_WZ(event, file, fileList) {
      if(this.isUpdate){
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessVideo_WZ(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.isUpdate = true;
      this.ruleForm_WZ.videoUrl = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
      console.log(this.ruleForm_WZ.videoUrl)
    },
    beforeAvatarUploadVideo_WZ(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.$message.error("上传视频大小不能超过 20MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccessCoverImg_WZ(res, file) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.ruleForm_WZ.coverImg = `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${res.data}`;
    },
    handleRemove_QZ(file, fileList) {
      this.ruleForm_QZ.goodPhoto = fileList;
    },
    goodsUpload_QZ(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm_QZ.goodPhoto = arr;
    },
    handlePictureCardPreview_QZ(file) {
      this.dialogImageUrl_QZ = file.url;
      this.dialogVisible_QZ = true;
    },
    handleRemove_ZS(file, fileList) {
      this.ruleForm_ZS.goodPhoto = fileList;
    },
    goodsUpload_ZS(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm_ZS.goodPhoto = arr;
    },
    handlePictureCardPreview_ZS(file) {
      this.dialogImageUrl_ZS = file.url;
      this.dialogVisible_ZS = true;
    },
    handleRemove_BS(file, fileList) {
      this.ruleForm_BS.goodPhoto = fileList;
    },
    goodsUpload_BS(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm_BS.goodPhoto = arr;
    },
    handlePictureCardPreview_BS(file) {
      this.dialogImageUrl_BS = file.url;
      this.dialogVisible_BS = true;
    },
  }
}
</script>

<style scoped>
.details_title{
  width: 400px;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
}
.details_date{
  display: flex;
  justify-content: space-evenly;
  border-bottom: 1px solid #c9c8c8;
  border-top: 1px solid #c9c8c8;
  padding: 15px 0;
  margin-top: 15px;
}
.details_con{
  margin-top: 20px;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 180px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
</style>
